html {
  /* font-size: 16px; */
  font-size: 1.8vh;
}

@media (min-width: 768px) {
  html {
    /* font-size: 16px; */
    font-size: 1.8vh;
  }
}

@media (min-width: 1280px) {
  html {
    /* font-size: 16px; */
    font-size: 2vh;
  }
}

@media (min-width: 1681px) {
  html {
    /* font-size: 16px; */
    font-size: 2vh;
  }
}

@media (min-width: 2500px) {
  html {
    /* font-size: 18px; */
    font-size: 2vh;
  }
}

/* Common */
.text-secondary-color {
  color: #8c8c8c;
}
