:where(.css-dev-only-do-not-override-49qm).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-49qm).ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-49qm).ant-menu-light.ant-menu-root.ant-menu-vertical,
:where(.css-dev-only-do-not-override-49qm).ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

.fifree-content-container {
  min-height: 90vh;
  margin: 1rem;
}

@media (max-width: 768px) {
  .fifree-content-container {
    margin: 0;
    padding: 0 1rem;
  }
}

@media (min-width: 1681px) {
  .fifree-content-container {
    padding: 0 15vw;
    padding-top: 3rem;
  }
}

@media (min-width: 2500px) {
  .fifree-content-container {
    padding: 0 20vw;
    padding-top: 3rem;
  }
}
